import React from 'react';
import { CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import { ModerableContentsList } from './moderable_contents/ModerableContentList';
import { IntegrationsPage } from './integrations/IntegrationPage';
import StatisticPage from './statistics/StatisticPage';

export const routes = [
    <CustomRoutes>
        <Route
            path="/social-networks/contents"
            element={<ModerableContentsList />}
        />
        <Route
            path="/social-networks/integrations"
            element={<IntegrationsPage />}
        />
        <Route path="/social-networks/statistics" element={<StatisticPage />} />
    </CustomRoutes>
];
