import React from 'react';
import { StatCard } from './StatCard';
import { CardContent, Stack, Typography } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';

interface MetricCardProps {
    title: string;
    value: string;
    icon: React.ReactNode;
    trend: 'up' | 'down';
    trendPercentage: number;
}

export function MetricCard({
    title,
    value,
    icon,
    trend,
    trendPercentage
}: MetricCardProps) {
    return (
        <StatCard sx={{ maxWidth: 300 }}>
            <CardContent>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack>
                        <Typography variant="h2" fontSize={18} marginBottom={3}>
                            {title}
                        </Typography>

                        <Typography fontSize={32}>{value}</Typography>
                    </Stack>

                    {icon}
                </Stack>

                <Stack direction="row" gap={1} marginTop={2}>
                    {trend === 'up' ? (
                        <TrendingUp color="success" />
                    ) : (
                        <TrendingDown color="error" />
                    )}
                    <Typography fontSize={14}>
                        <Typography
                            fontSize={14}
                            fontWeight="bold"
                            display="inline"
                            color={trend === 'up' ? 'success.main' : 'error'}
                        >
                            {trendPercentage}%
                        </Typography>{' '}
                        en {trend === 'up' ? 'hausse' : 'baisse'} par rapport à
                        la période précédente.
                    </Typography>
                </Stack>
            </CardContent>
        </StatCard>
    );
}
