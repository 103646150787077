import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useTranslate, Link } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Point as PointIcon } from '@logora/debate.icons.regular_icons';
import { flags } from '../../debate/translation_entries/TranslationList';
import FullNameField from '../../inputs/FullNameField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import PersonIcon from '@mui/icons-material/Person';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import dayjs from 'dayjs';
import ModerationReport from './ModerationReport';
import { Stack } from '@mui/material';
import { YouTube } from '@mui/icons-material';
import { Facebook } from '@mui/icons-material';

const socialIcons = {
    youtube: <YouTube sx={{ fill: '#FF0000' }} />,
    facebook: <Facebook sx={{ fill: '#3B5998' }} />
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    content: {
        padding: '15px',
        margin: '15px 0 20px 0',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px'
    },
    user: {
        marginBottom: '15px',
        display: 'block'
    },
    contentHeader: {
        display: 'flex',
        flexDirection: 'row'
    },
    position: {
        alignSelf: 'center',
        display: 'block',
        marginBottom: '15px',
        backgroundColor: '#5B7FF122 !important',
        padding: '.5em .75em',
        borderRadius: '6px',
        color: '#5B7FF1'
    },
    subtitle: {
        padding: '15px 0',
        color: 'color: rgba(0, 0, 0, 0.6)',
        fontSize: '0.75em'
    },
    moderableContent: {
        marginBottom: '15px'
    },
    footer: {
        color: '#999',
        display: 'flex',
        flexDirection: 'row',
        width: 'max-content',
        backgroundColor: '#e7e7e7',
        padding: '0.5em 0.75em',
        borderRadius: '6px',
        fontSize: '14px',
        '& span': {
            marginLeft: '5px'
        }
    },
    sourcesContainer: {
        backgroundColor: '#e8e8e8',
        border: '1px solid #e8e8e8',
        borderRadius: '6px',
        color: '#222 !important',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        justifyContent: 'flex-start',
        maxWidth: 'max-content',
        outline: 'none !important',
        padding: '5px',
        marginTop: '5px',
        textDecoration: 'none !important'
    },
    originLink: {
        display: 'flex',
        textDecoration: 'none',
        color: 'rgb(91, 127, 241)',
        paddingRight: '5px',
        alignSelf: 'center'
    },
    originAbsoluteLink: {
        fontSize: '12px'
    },
    entryHeader: {
        display: 'flex',
        flexDirection: 'row'
    },
    statusHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '15px'
    },
    entryType: {
        alignSelf: 'center',
        display: 'block',
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
        padding: '.5em .75em',
        borderRadius: '6px',
        color: 'rgba(0, 0, 0, 0.87)',
        marginRight: '15px'
    },
    status: {
        alignSelf: 'center',
        display: 'block',
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
        padding: '5px',
        borderRadius: '6px',
        color: '#999',
        fontSize: '14px'
    },
    entryStats: {
        display: 'flex',
        flexDirection: 'row'
    },
    entryStatItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #5B7FF1',
        borderRadius: '6px',
        padding: '.5em .75em',
        color: '#5B7FF1',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '15px',
        whiteSpace: 'nowrap',
        '& svg': {
            paddingLeft: '5px'
        }
    },
    reportItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #FF000022',
        background: '#FF000022',
        borderRadius: '6px',
        padding: '.5em .75em',
        color: '#FF0000',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '15px',
        '& svg': {
            paddingLeft: '5px'
        }
    },
    showParent: {
        display: 'flex',
        flexDirection: 'row',
        padding: '.5em .75em',
        color: '#5B7FF1',
        alignItems: 'center',
        cursor: 'pointer',
        marginLeft: 'auto'
    },
    createdAt: {
        marginLeft: 'auto',
        alignSelf: 'center',
        fontSize: '12px',
        color: '#666',
        marginBottom: '15px',
        marginRight: '15px'
    },
    drawerTitle: {
        display: 'block',
        width: 'max-content',
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
        padding: '.5em .75em',
        borderRadius: '6px',
        color: 'rgba(0, 0, 0, 0.87)',
        margin: '15px 15px 0 15px'
    },
    moderableTitle: {
        marginBottom: '10px'
    },
    pointIcon: {
        height: '25px',
        width: '25px',
        '& > path': {
            fill: '#5B7FF1'
        },
        '& > circle': {
            stroke: '#5B7FF1'
        }
    },
    translatedTitle: {
        marginBottom: '5px'
    }
}));

const ModerableElement = ({
    parentId = null,
    moderable = null,
    moderableType,
    moderableScore,
    hideInfos = false,
    showStatus = false
}) => {
    const [entry, setEntry] = useState(moderable || null);
    const [showParent, setShowParent] = useState(false);
    const [showReports, setShowReports] = useState(false);
    const [parents, setParents] = useState([]);
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`messages`, {
                id: parentId,
                with_parents: true
            });
            const entry = response.data;
            const parents = response.data.parents;
            if (entry) {
                setEntry(entry);
                if (parents && parents.length > 0) {
                    setParents(parents);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    const showModerableContent = (entry) => {
        switch (moderableType) {
            case 'Message':
                return entry.content;
            case 'Proposal':
                return entry.content;
            case 'DebateSuggestion':
                return entry.name;
            case 'User':
                return entry.description;
            default:
                return entry.content;
        }
    };

    const showModerableType = (entry) => {
        switch (moderableType) {
            case 'Message':
                if (entry.is_reply) {
                    return translate('resources.moderation_entries.type.reply');
                } else if (entry.group_type === 'Source') {
                    return translate(
                        'resources.moderation_entries.type.comment'
                    );
                } else {
                    return translate(
                        'resources.moderation_entries.type.message'
                    );
                }
            case 'Proposal':
                return translate('resources.moderation_entries.type.proposal');
            case 'DebateSuggestion':
                return translate(
                    'resources.moderation_entries.type.debate_suggestion'
                );
            case 'User':
                return translate('resources.moderation_entries.type.user');
            default:
                return null;
        }
    };

    const showModerableOrigin = (entry) => {
        switch (moderableType) {
            case 'Message':
                if (entry.group_type === 'Source') {
                    return entry.group.source_url ? (
                        <a
                            className={classes.originLink}
                            href={entry.group?.source_url}
                            target="_blank"
                            rel={'noopener noreferrer'}
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(91, 127, 241)'
                            }}
                        >
                            {entry.group?.title}
                        </a>
                    ) : (
                        entry.group.title
                    );
                } else {
                    return (
                        <a
                            className={classes.originLink}
                            href={entry?.direct_url}
                            target="_blank"
                            rel={'noopener noreferrer'}
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(91, 127, 241)'
                            }}
                        >
                            {entry?.group?.name}
                        </a>
                    );
                }
            case 'Proposal':
                return (
                    <a
                        className={classes.originLink}
                        href={entry?.direct_url}
                        target="_blank"
                        rel={'noopener noreferrer'}
                        style={{
                            textDecoration: 'none',
                            color: 'rgb(91, 127, 241)'
                        }}
                    >
                        {entry.consultation?.title}
                    </a>
                );
            case 'DebateSuggestion':
            default:
                return null;
        }
    };

    useEffect(() => {
        if (parentId && moderable == null) {
            fetchElement();
        } else if (moderable !== null) {
            setEntry(moderable);
        }
    }, [moderable]);

    return (
        entry && (
            <>
                {entry?.moderation_entry?.moderable_type !==
                    'SocialModerator::ModerableContent' &&
                    !hideInfos && (
                        <div className={classes.entryHeader}>
                            <div className={classes.entryType}>
                                {showModerableType(entry)}
                            </div>
                            {showModerableOrigin(entry)}
                        </div>
                    )}
                <Paper className={classes.content} elevation={1}>
                    <div className={classes.contentHeader}>
                        {entry?.moderation_entry?.moderable_type ===
                            'SocialModerator::ModerableContent' ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{
                                    textTransform: 'capitalize',
                                    marginBottom: '15px'
                                }}
                            >
                                {socialIcons[entry?.moderable_source.provider]}{' '}
                                Utilisateur {entry?.moderable_source.provider}
                            </Stack>
                        ) : (
                            <Link
                                to={`/users/${moderableType === 'User'
                                        ? entry.hash_id
                                        : entry.author?.hash_id
                                    }`}
                            >
                                <div
                                    className={classes.user}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        alignItems: 'center',
                                        color: 'rgb(91, 127, 241)',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FullNameField
                                        user={entry.author || entry}
                                    />
                                </div>
                            </Link>
                        )}

                        {entry?.position && (
                            <>
                                <span className={classes.createdAt}>
                                    {dayjs(entry.created_at).format(
                                        'DD-MM-YYYY HH:m'
                                    )}
                                </span>
                                <div className={classes.position}>
                                    {entry.position.name}
                                </div>
                            </>
                        )}
                    </div>
                    {moderableType === 'Proposal' && (
                        <b className={classes.moderableTitle}>{entry.title}</b>
                    )}
                    <Box sx={{ position: 'relative' }}>
                        <div
                            className={classes.moderableContent}
                            dangerouslySetInnerHTML={{
                                __html: showModerableContent(entry)
                            }}
                        ></div>
                        {(moderableType === 'Proposal' ||
                            moderableType === 'Message') && (
                                <a
                                    className={classes.originAbsoluteLink}
                                    href={entry?.direct_url}
                                    target="_blank"
                                    rel={'noopener noreferrer'}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'rgb(91, 127, 241)'
                                    }}
                                >
                                    {translate(
                                        'resources.moderation_entries.label.see_message'
                                    )}
                                </a>
                            )}
                    </Box>
                    {entry?.sources && (
                        <div className={classes.subtitle}>
                            {entry?.sources?.map((source) => (
                                <a
                                    className={classes.sourcesContainer}
                                    href={source.source_url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {source.title}
                                </a>
                            ))}
                        </div>
                    )}
                    {entry?.upvotes > 0 ? (
                        <>
                            <div className={classes.footer}>
                                <div>
                                    {translate(
                                        'resources.moderation_entries.label.upvotes'
                                    )}
                                </div>
                                <span>{entry.upvotes}</span>
                            </div>
                        </>
                    ) : null}
                    {showStatus && moderableType === 'Message' && (
                        <div className={classes.statusHeader}>
                            <div className={classes.status}>
                                {entry.status === 'rejected'
                                    ? translate(
                                        'resources.messages.status.rejected'
                                    )
                                    : entry.status === 'accepted'
                                        ? translate(
                                            'resources.messages.status.accepted'
                                        )
                                        : translate(
                                            'resources.messages.status.pending'
                                        )}
                            </div>
                        </div>
                    )}
                </Paper>
                {parentId &&
                    entry.translation_entries.length > 0 &&
                    entry.translation_entries.map((e) => (
                        <Paper
                            sx={{ marginLeft: '25px !important' }}
                            className={classes.content}
                            elevation={1}
                        >
                            <span
                                className={classes.translatedTitle}
                            >{`${e.target_language.toUpperCase()} ${flags[e.target_language]
                                }`}</span>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: e.translated_content
                                }}
                            ></div>
                        </Paper>
                    ))}
                {parents &&
                    parents.length > 0 &&
                    parents.map((elm) => (
                        <ModerableElement
                            key={elm.id}
                            moderable={elm}
                            moderableType={'Message'}
                            hideInfos
                        />
                    ))}
                {!hideInfos && (
                    <>
                        <div className={classes.entryStats}>
                            {moderableScore > 0 && (
                                <Tooltip
                                    title={translate(
                                        'resources.moderation_entries.label.moderation_score'
                                    )}
                                >
                                    <div className={classes.entryStatItem}>
                                        {Math.floor(100 - moderableScore)} %{' '}
                                        <AvTimerIcon />
                                    </div>
                                </Tooltip>
                            )}
                            {entry.author?.moderation_score > 0 && (
                                <Tooltip
                                    title={translate(
                                        'resources.moderation_entries.label.user_score'
                                    )}
                                >
                                    <div className={classes.entryStatItem}>
                                        {entry.author?.moderation_score} %{' '}
                                        <PersonIcon />
                                    </div>
                                </Tooltip>
                            )}
                            {entry.score > 0 && (
                                <Tooltip
                                    title={translate(
                                        'resources.moderation_entries.label.relevance_score'
                                    )}
                                >
                                    <div className={classes.entryStatItem}>
                                        {Math.floor(entry.score)}
                                        <PointIcon
                                            className={classes.pointIcon}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {entry.reports?.length > 0 && (
                                <>
                                    <Tooltip
                                        title={translate(
                                            'resources.moderation_entries.label.reports',
                                            { count: entry.reports.length }
                                        )}
                                    >
                                        <div
                                            onClick={() => setShowReports(true)}
                                            className={classes.reportItem}
                                        >
                                            {translate(
                                                'resources.moderation_entries.label.show_reports',
                                                {
                                                    smart_count:
                                                        entry.reports.length
                                                }
                                            )}{' '}
                                            <ErrorOutlineIcon />
                                        </div>
                                    </Tooltip>
                                    <Drawer
                                        anchor={'right'}
                                        open={showReports}
                                        onClose={() => setShowReports(false)}
                                    >
                                        <div className={classes.drawerTitle}>
                                            {translate(
                                                'resources.reports.name',
                                                { smart_count: 2 }
                                            )}
                                        </div>
                                        {entry.reports.map((report) => (
                                            <Box
                                                sx={{
                                                    width: '500px',
                                                    padding: '15px'
                                                }}
                                            >
                                                <ModerationReport
                                                    report={report}
                                                />
                                            </Box>
                                        ))}
                                    </Drawer>
                                </>
                            )}
                            {entry?.reply_to_id && (
                                <React.Fragment key={'left'}>
                                    <Tooltip
                                        title={translate(
                                            'resources.reports.labels.see_message'
                                        )}
                                    >
                                        <div
                                            onClick={() => setShowParent(true)}
                                            className={classes.showParent}
                                        >
                                            {translate(
                                                'resources.moderation_entries.label.parent'
                                            )}
                                        </div>
                                    </Tooltip>
                                    <Drawer
                                        anchor={'right'}
                                        open={showParent}
                                        onClose={() => setShowParent(false)}
                                    >
                                        <div className={classes.drawerTitle}>
                                            {translate(
                                                'resources.moderation_entries.label.parent_argument'
                                            )}
                                        </div>
                                        <Box
                                            sx={{
                                                width: '500px',
                                                padding: '15px'
                                            }}
                                        >
                                            <ModerableElement
                                                parentId={entry.reply_to_id}
                                                moderableType={
                                                    entry?.moderable_type
                                                }
                                                hideInfos
                                            />
                                        </Box>
                                    </Drawer>
                                </React.Fragment>
                            )}
                        </div>
                    </>
                )}
            </>
        )
    );
};

export default ModerableElement;
