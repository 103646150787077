import React, { useEffect } from "react";
import { useTranslate, useUpdate, useNotify, useRedirect, useRefresh } from "react-admin";
import Button from "@mui/material/Button";
import { ThumbUp, ThumbDown, ArrowForward } from "@mui/icons-material";

const StatusButton = ({ resource, record, updateObject, type, noRedirect = false, currentResource, approveMessage, rejectMessage, onUpdate, acceptKey, rejectKey, passKey }) => {
	const notify = useNotify();
	const redirectTo = useRedirect();
  const refresh = useRefresh();
	const translate = useTranslate();

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (e) => {
    e.stopPropagation();
    if (e.ctrlKey && e.key === acceptKey && type === "ACCEPT") {
      e.preventDefault();
      approve(); 
    }
    if (e.ctrlKey && e.key === passKey && type === "SKIP") {
      e.preventDefault();
      approve(); 
    }
  }

	const [approve, { isLoading }] = useUpdate(
    resource, 
    { 
      id: record.id, 
      data: updateObject,
    }, 
    {
      onSuccess: () => {
        notify(translate('resources.moderation_entries.notifications.moderation_success'), { type: "info" });
        refresh();
        onUpdate && onUpdate();
        !noRedirect && redirectTo(`/${currentResource || resource}`);
      },
      onFailure: () => {
        notify(translate('resources.moderation_entries.notifications.moderation_fail'), { type: 'warning' });
      },
      mutationMode: "pessimistic"
    }
  );

  const displayButton = () => {
    switch(type) {
      case "ACCEPT":
        return (
          <Button variant='outlined' style={{ color: "#4CAF50" }} size='small' onClick={() => approve()} disabled={isLoading}>
            <ThumbUp color='#4CAF50' style={{ paddingRight: "0.5em", color: "#4CAF50" }} />
            { approveMessage || translate("pos.actions.accept") }
          </Button>
        );
      case "REJECT":
        return (
          <Button variant="outlined" style={{ color: "#F44336"}} size="small" onClick={() => approve()} disabled={isLoading}>
            <ThumbDown color="#F44336" style={{ paddingRight: '0.5em', color: '#F44336' }} />
            { rejectMessage || translate('pos.actions.reject') }
          </Button>
        );
      case "SKIP":
        return (
          <Button variant="outlined" style={{ color: "rgba(91, 127, 241)"}} size="small" onClick={() => approve()} disabled={isLoading}>
            <ArrowForward color="rgba(91, 127, 241)" style={{ paddingRight: '0.5em', color: 'rgba(91, 127, 241)' }} />
            { translate('pos.actions.skip') }
          </Button>
        )
      default:
        return null;
    }
  }

	return (
		<>
      { displayButton() }
    </>
	);
};

export default StatusButton;